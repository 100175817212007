import React, { useRef, useEffect } from 'react'
import Alert from 'react-bootstrap/Alert'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
function Navbar2({ navItems, direction, language, currentElementIndexInViewport }) {
    const divRefN2 = useRef(null)
    
    useEffect(() => {
        const handleScroll = () => {
            if (divRefN2.current) {
                
                const { top } = divRefN2.current.getBoundingClientRect()
                // Check if the top of the div is at the top of the viewport
                if(top < 100){
                    divRefN2.current.classList.remove('mx-3')
                    divRefN2.current.classList.add('topN2-55')
                    divRefN2.current.classList.add('alert-nav1')
                }else{
                    divRefN2.current.classList.add('mx-3')
                    divRefN2.current.classList.remove('topN2-55')
                    divRefN2.current.classList.remove('alert-nav1')
                }
            }
        }

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll)

        // Cleanup function
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    function next() {
        const activeNavItem = document.querySelector(".active-scroll-spy")
        var nextElement = activeNavItem.nextElementSibling
        if (nextElement) {
            activeNavItem.classList.remove('active-scroll-spy')
            nextElement.classList.add('active-scroll-spy')
            document.getElementById(nextElement.id).scrollIntoView({ inline: "center" })
            setTimeout(function () {
                let targetId = nextElement.id
                const activeItemId = targetId.replace('nav2', 'section')
                document.getElementById(activeItemId).scrollIntoView()
            }, 100)

        }
    }

    function prev() {
        const activeNavItem = document.querySelector(".active-scroll-spy")
        var prevElement = activeNavItem.previousElementSibling
        if (prevElement) {
            activeNavItem.classList.remove('active-scroll-spy')
            prevElement.classList.add('active-scroll-spy')
            prevElement.click()
            document.getElementById(prevElement.id).scrollIntoView({ inline: "center" })
            setTimeout(function () {
                let targetId = prevElement.id
                const activeItemId = targetId.replace('nav2', 'section')
                document.getElementById(activeItemId).scrollIntoView()
            }, 100)
        }
    }

    function handleSSItem(e) {
        let targetId = e.target.parentNode.id
        const activeItemId = targetId.replace('nav2', 'section')
        document.getElementById(activeItemId).scrollIntoView()
    }
    return (
        <>
            <Alert className='d-flex justify-content-between align-items-center text-light text-uppercase body-bg ff-domaine mx-3 border-0 sticky-top' data-cy="nav-wrapper" ref={divRefN2}>
                <div><Image src="../assets/prev.png" className='nav-btn' onClick={direction === "ltr" ? prev : next} /></div>
                <Row className='h-scroll w-100 mx-1 navbar2 fs-5 h-nav2 align-items-center' dir={direction}>
                    {
                        navItems?.map((item, index) => {
                            let dirClass = ''
                            if (language === 'AR') dirClass = 'colAr'
                            else dirClass = ''
                            return <Col key={"navbar2-" + index} id={"nav2-" + index} className={currentElementIndexInViewport === index ? ("active-scroll-spy " + dirClass) : ("text-nav2 " + dirClass)}><span onClick={handleSSItem} className="d-inline-block w-100 text-center px-2">{language === "AR" ? item.ArabicName : item.Name}</span></Col>
                        })
                    }
                </Row>
                <div><Image src="../assets/next.png" className='nav-btn' onClick={direction === "ltr" ? next : prev} /></div>
            </Alert>
        </>
    )
}

export default Navbar2