// import FEEDBACK from "./feedback"
import LANGUAGE from "./language";
import HOMEBTN from "./home-button";
function Banner({ handleLanguage, language, direction }) {
    return (
        <div className="image-container" dir={direction}>
            <div className="d-flex position-absolute z-1 mx-2 mt-1">
                <HOMEBTN language={language} />
                <LANGUAGE handleLanguage={handleLanguage} language={language} />
            </div>
            {/* <FEEDBACK direction={direction} language={language}/> */}
            <img src="../assets/banner.png" className="d-block w-100 top-banner background-image" alt="Background" />
            <img src="../assets/logo.png" className="overlay-image" alt="Overlay" />
        </div>
    );
}

export default Banner;