import Button from 'react-bootstrap/Button'
function HomeBTN({ language }) {
    function openurl(url) {
        window.location.href = url
    }
    return (
            <Button className="border-0 ff-montserratbold py-0 home-btn" variant="outline-light" onClick={() => openurl("https://awani.ae/")}>{language === "AR" ? 'الرئيسية' : 'HOME'}</Button>
    );
}

export default HomeBTN;