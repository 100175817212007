import Container from 'react-bootstrap/Container'
function Allergens({ direction, language }) {
    return (
        <Container>
            <p className='text-allergens' dir={direction}>{language === "AR" ? "يرجى ملاحظة أن بعض الأطعمة والمشروبات التي نقدمها قد تحتوي على مواد مسببة للحساسية. على الرغم من اتخاذ الاحتياطات اللازمة لإبقائها منفصلة، ​​إلا أننا لا نستطيع التأكد من أن أيًا من أطعمةنا ومشروباتنا خالية من مسببات الحساسية." : "Please note that some of our food and beverages may contain allergens. Whilst precautions are taken to keep them separate, we cannot be certain that any of our food and beverages are “allergen free”."}
            </p>
        </Container>
    );
}

export default Allergens;