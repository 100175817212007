import NAV1 from './navbar1'
import MENU from './menu'
import OFFER from './offer'
import Placeholder from 'react-bootstrap/Placeholder'

function Body({ activeTab, direction, categories, loadingCategories, subCategories, loadingSubCategories, products, productsAr, loadingProducts, handleNav1Click, language, currency }) {

    return (
        <div className="body rounded-top-5 pt-1">
            {
                loadingCategories ? (<Placeholder.Button xs={4} aria-hidden="true" />) : (<NAV1 activeTab={activeTab} direction={direction} categories={categories} handleNav1Click={handleNav1Click} language={language}/>)
            }
            { !loadingSubCategories && activeTab === 237 ? (<OFFER direction={direction} subCategories={subCategories} products={products} productsAr={productsAr} language={language} currency={currency}/>) : ('')}
            { !loadingSubCategories && activeTab !== 237? (<MENU direction={direction} subCategories={subCategories} products={products} productsAr={productsAr} loadingProducts={loadingProducts} language={language} currency={currency} activeTab={activeTab} />) : ('')}
        </div>
    );
}

export default Body;