import Container from 'react-bootstrap/Container'
function Breakfast({ direction, language }) {
    return (
        <Container>
            <p className='text-breakfast' dir={direction}>
                {language === 'AR' ? 'يُقدّم حتى ١٢ ظهراً' : 'Served Until 12:00 PM'}
            </p>
        </Container>
    );
}

export default Breakfast;