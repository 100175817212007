import { useRef } from "react"
import NAV2 from './navbar2'
import OfferList from './offer_list'
import { Scrollspy } from '@makotot/ghostui'

function Offer({ direction, subCategories, products, productsAr, language, currency }) {
    function subcategoryProducts(subCategoryId) {
        let subCategoryProducts = ''
        if (language === "AR") subCategoryProducts = productsAr.filter(item => item.Id === subCategoryId)
        else subCategoryProducts = products.filter(item => item.Id === subCategoryId)
        return subCategoryProducts[0].Menu;
    }
    const sectionRefs = [
        useRef(null),
        useRef(null)
    ];
    return (
        <div className="pt-1">
            <Scrollspy sectionRefs={sectionRefs}>
                {({ currentElementIndexInViewport }) => (
                    <div>
                        <NAV2 navItems={subCategories} direction={direction} language={language} currentElementIndexInViewport={currentElementIndexInViewport} />
                        <div data-cy="section-wrapper">
                            {
                                subCategories?.map((item, index) => {
                                    const subCategorProducts = subcategoryProducts(item.Id)
                                    return <OfferList key={"offerlist-" + index} direction={direction} products={subCategorProducts} scrollId={index} sectionRefs={sectionRefs} language={language} currency={currency} />
                                })
                            }
                        </div>
                    </div>
                )}
            </Scrollspy>
        </div>
    );
}

export default Offer;